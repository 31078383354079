import { GameController } from "../minesweeperpve/GameController";


export const GamePvE = () => {

    return (
        <>
            <GameController />
        </>
    );
}