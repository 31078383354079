import { GameControllerV2 } from "../minesweeperpve/GameControllerV2"


export const GamePvEOffline = () => {


    return (
        <>
            <GameControllerV2 />
        </>
    )
}